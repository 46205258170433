import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  ConceptMedia,
} from '../../../components/_index';
import { useMobile } from '../../../utils/use-series';
import '../../../assets/_sass/page/privacy.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'Banquet event TERMS OF USE',
            sub: 'ご宴会・催事規約',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/privacy/site/kv.png',
              },
              imgSp: {
                src: '/assets/images/privacy/site/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '規約・約款',
                path: '/privacy/',
              },
            ],
            current: {
              label: 'ご宴会・催事規約',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect p_privacy">
        <LWrap exClass="u_mbExLarge">
          <h2 className="c_headingLv2">ご宴会・催事規約</h2>
          <p className="u_mb25">
            当ホテルでは、宴会場のご利用に関して以下のとおり定めております。必ずご一読の上、予めご了承くださいますようお願い申し上げます。（ご結婚披露宴につきましては別途規約を定めております。）
          </p>
          <h3 className="c_headingLv5">1．予約の成立等</h3>
          <p className="u_mb25">
            ご宴会等のお申し込みをいただく場合は、ホテルが定めた「宴会等申込書」にお申し込み内容をご記入のうえ、申込金を添えて提出していただきます。
            <br />
            申込金は、お申し込み時のご宴会等見積金額の２０％を基本とし、ホテルより提示させていただきます。「宴会等申込書」の内容をホテルが確認のうえ、お申し込みをお受けするとの意思表示をした時をもって予約の成立とさせていただきます。
            <br />
            尚、お申し込みをお受けできないときは、申込金を利息を付さずに、予めご指定いただいた口座に振込にてご返金申し上げます。
          </p>
          <h3 className="c_headingLv5">2. 宴会室料と追加室料</h3>
          <p className="u_mb25">
            宴会場の使用時間とは、設営から撤去までを含みます。事前にホテルの担当係員と取り決めさせていただいたご使用時間（以下契約時間と称します）は、所定の室料をお支払いいただきますが、この契約時間を超過した場合には、所定の追加室料を頂戴いたします。但し、契約時間の超過に応じられない場合もございますので予めご了承ください。
          </p>
          <h3 className="c_headingLv5">3. 前払い金</h3>
          <p className="u_mb25">
            ご宴会等の予約が成立した場合、お客様が了承された見積金額から、お支払いいただいております申込金との差額に相当する前払い金を、以下に定めるとおり、現金または銀行振込にてお支払いください。当該前払い金を期限までにお支払いいただけない場合、予約を解約させていただくことがございます。
          </p>
          <table className="c_fixedTable c_fixedTable--party">
            <thead>
              <tr>
                <th className="thTitle">見積金額</th>
                <th className="thTitle">支払期限</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>～300万円</td>
                <td>開催7日前まで</td>
              </tr>
              <tr>
                <td>300～1000万円</td>
                <td>開催15日前まで</td>
              </tr>
              <tr>
                <td>1000万円超～</td>
                <td>開催1ヶ月前まで</td>
              </tr>
            </tbody>
          </table>
          <h3 className="c_headingLv5">4. お打合せ</h3>
          <p className="u_mb25">
            ご宴会の内容については、事前にお打合せください。また、最終的なお打合せは、ご宴会等開催日の１０日前までにホテルの担当係員とお済ませください。
          </p>
          <h3 className="c_headingLv5">5. お支払い</h3>
          <p className="u_mb25">
            ご宴会等に関する一切の費用（お支払いいただいております申込金および前払い金との差額）は、ご宴会等開催日から３０日以内に現金または銀行振込にてお支払いください。尚、前払い金等に余剰が出ましたときは、ご宴会等開催後、速やかに精算の上、上記１．に記載の方法にてご返金申し上げます。
          </p>
          <h3 className="c_headingLv5">6. 有料人数の最終確認</h3>
          <p className="u_mb25">
            ご宴会等の最終お打合せ後の出席人数の変更やお料理などをご用意させていただく人数・食数（以下、有料人数と称します）の最終変更は、ご宴会等開催日の３日前正午までにホテルの担当係員へご連絡ください。
            <br />
            上記を過ぎてご出席が有料人数より減少した場合でも、最終ご注文数（有料人数）の料金を頂戴させていただきます。
          </p>
          <h3 className="c_headingLv5">7. 取り消し料と期日変更料</h3>
          <p className="u_mb25">
            すでにご予約いただいたご宴会等をお取り消しになる場合および開催期日を変更される場合には、下記の取り消し料または期日変更料を頂戴いたします。尚、後記１４．の規約により解約された場合にも、下記の取り消し料を頂戴いたします。
          </p>
          {!isSp ? (
            <table className="c_fixedTable c_fixedTable--party">
              <thead>
                <tr>
                  <th></th>
                  <th colSpan={2}>A．一般宴会の取り消し料および期日変更料</th>
                  <th colSpan={2}>B．展示会の取り消し料および期日変更料</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th rowSpan={3} className="u_tal">
                    開催日の
                    <br />
                    121日前まで
                  </th>
                  <td colSpan={2}>
                    日数は、お申し出をいただきました日より起算いたします。
                  </td>
                  <td colSpan={2}>
                    日数は、お申し出をいただきました日より起算いたします。
                  </td>
                </tr>
                <tr>
                  <td>取り消し</td>
                  <td>実費諸費用の請求</td>
                  <td>取り消し</td>
                  <td>実費諸費用の請求</td>
                </tr>
                <tr>
                  <td>変更</td>
                  <td>実費諸費用の請求</td>
                  <td>変更</td>
                  <td>実費諸費用の請求</td>
                </tr>

                <tr>
                  <th rowSpan={2} className="u_tal">
                    開催日の
                    <br />
                    120日前から61日前まで
                  </th>
                  <td>取り消し</td>
                  <td>
                    ご予約会場の宴会時間・会議室料の30％
                    <br />
                    および実費諸費用の請求
                  </td>
                  <td>取り消し</td>
                  <td>
                    ご予約会場の宴会時間・会議室料の30％
                    <br />
                    および実費諸費用の請求
                  </td>
                </tr>
                <tr>
                  <td>変更</td>
                  <td>
                    ご予約会場の宴会時間・会議室料の20％
                    <br />
                    および実費諸費用の請求
                  </td>
                  <td>変更</td>
                  <td>
                    ご予約会場の宴会時間・会議室料の20％
                    <br />
                    および実費諸費用の請求
                  </td>
                </tr>

                <tr>
                  <th rowSpan={2} className="u_tal">
                    開催日の
                    <br />
                    60日前から31日前まで
                  </th>
                  <td>取り消し</td>
                  <td>
                    ご予約会場の宴会時間・会議室料の40％
                    <br />
                    および実費諸費用の請求
                  </td>
                  <td>取り消し</td>
                  <td>
                    ご予約会場の宴会時間・会議室料の40％
                    <br />
                    および実費諸費用の請求
                  </td>
                </tr>
                <tr>
                  <td>変更</td>
                  <td>
                    ご予約会場の宴会時間・会議室料の30％
                    <br />
                    および実費諸費用の請求
                  </td>
                  <td>変更</td>
                  <td>
                    ご予約会場の宴会時間・会議室料の30％
                    <br />
                    および実費諸費用の請求
                  </td>
                </tr>

                <tr>
                  <th rowSpan={2} className="u_tal">
                    開催日の
                    <br />
                    30日前から16日前まで
                  </th>
                  <td>取り消し</td>
                  <td>
                    お見積もり金額の60％
                    <br />
                    および実費諸費用の請求
                  </td>
                  <td>取り消し</td>
                  <td>
                    ご予約会場の宴会時間・会議室料の60％
                    <br />
                    および実費諸費用の請求
                  </td>
                </tr>
                <tr>
                  <td>変更</td>
                  <td>
                    お見積もり金額の50％
                    <br />
                    および実費諸費用の請求
                  </td>
                  <td>変更</td>
                  <td>
                    ご予約会場の宴会時間・会議室料の50％
                    <br />
                    および実費諸費用の請求
                  </td>
                </tr>

                <tr>
                  <th rowSpan={2} className="u_tal">
                    開催日の
                    <br />
                    15日前から3日前正午まで
                  </th>
                  <td>取り消し</td>
                  <td>
                    お見積もり金額の80％
                    <br />
                    および実費諸費用の請求
                  </td>
                  <td>取り消し</td>
                  <td>
                    ご予約会場の宴会時間・会議室料の80％
                    <br />
                    および実費諸費用の請求
                  </td>
                </tr>
                <tr>
                  <td>変更</td>
                  <td>
                    お見積もり金額の70％
                    <br />
                    および実費諸費用の請求
                  </td>
                  <td>変更</td>
                  <td>
                    ご予約会場の宴会時間・会議室料の70％
                    <br />
                    および実費諸費用の請求
                  </td>
                </tr>

                <tr>
                  <th rowSpan={2} className="u_tal">
                    開催日の
                    <br />
                    3日前正午より当日
                  </th>
                  <td>取り消し</td>
                  <td>お見積もり金額の100％</td>
                  <td>取り消し</td>
                  <td>
                    ご予約会場の宴会時間・会議室料の100％
                    <br />
                    および実費諸費用の請求
                  </td>
                </tr>
                <tr>
                  <td>変更</td>
                  <td>お見積もり金額の100％</td>
                  <td>変更</td>
                  <td>
                    ご予約会場の宴会時間・会議室料の100％
                    <br />
                    および実費諸費用の請求
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <>
              <table className="c_fixedTable c_fixedTable--party">
                <thead>
                  <tr>
                    <th style={{ width: '85px' }}></th>
                    <th colSpan={2} className="thTitle">
                      A．一般宴会の取り消し料および期日変更料
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th rowSpan={3} className="u_tal">
                      開催日の
                      <br />
                      121日前まで
                    </th>
                    <td colSpan={2}>
                      日数は、お申し出をいただきました日より起算いたします。
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '70px' }}>取り消し</td>
                    <td>実費諸費用の請求</td>
                  </tr>
                  <tr>
                    <td>変更</td>
                    <td>実費諸費用の請求</td>
                  </tr>

                  <tr>
                    <th rowSpan={2} className="u_tal">
                      開催日の
                      <br />
                      120日前から61日前まで
                    </th>
                    <td>取り消し</td>
                    <td>
                      ご予約会場の宴会時間・会議室料の30％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>
                  <tr>
                    <td>変更</td>
                    <td>
                      ご予約会場の宴会時間・会議室料の20％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>

                  <tr>
                    <th rowSpan={2} className="u_tal">
                      開催日の
                      <br />
                      60日前から31日前まで
                    </th>
                    <td>取り消し</td>
                    <td>
                      ご予約会場の宴会時間・会議室料の40％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>
                  <tr>
                    <td>変更</td>
                    <td>
                      ご予約会場の宴会時間・会議室料の30％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>

                  <tr>
                    <th rowSpan={2} className="u_tal">
                      開催日の
                      <br />
                      30日前から16日前まで
                    </th>
                    <td>取り消し</td>
                    <td>
                      お見積もり金額の60％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>
                  <tr>
                    <td>変更</td>
                    <td>
                      お見積もり金額の50％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>

                  <tr>
                    <th rowSpan={2} className="u_tal">
                      開催日の
                      <br />
                      15日前から3日前正午まで
                    </th>
                    <td>取り消し</td>
                    <td>
                      お見積もり金額の80％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>
                  <tr>
                    <td>変更</td>
                    <td>
                      お見積もり金額の70％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>

                  <tr>
                    <th rowSpan={2} className="u_tal">
                      開催日の
                      <br />
                      3日前正午より当日
                    </th>
                    <td>取り消し</td>
                    <td>お見積もり金額の100％</td>
                  </tr>
                  <tr>
                    <td>変更</td>
                    <td>お見積もり金額の100％</td>
                  </tr>
                </tbody>
              </table>

              <table className="c_fixedTable c_fixedTable--party">
                <thead>
                  <tr>
                    <th style={{ width: '85px' }}></th>
                    <th colSpan={2} className="thTitle">
                      B．展示会の取り消し料および期日変更料
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th rowSpan={3} className="u_tal">
                      開催日の
                      <br />
                      121日前まで
                    </th>
                    <td colSpan={2}>
                      日数は、お申し出をいただきました日より起算いたします。
                    </td>
                  </tr>
                  <tr>
                    <td style={{ width: '70px' }}>取り消し</td>
                    <td>実費諸費用の請求</td>
                  </tr>
                  <tr>
                    <td>変更</td>
                    <td>実費諸費用の請求</td>
                  </tr>

                  <tr>
                    <th rowSpan={2} className="u_tal">
                      開催日の
                      <br />
                      120日前から61日前まで
                    </th>
                    <td>取り消し</td>
                    <td>
                      ご予約会場の宴会時間・会議室料の30％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>
                  <tr>
                    <td>変更</td>
                    <td>
                      ご予約会場の宴会時間・会議室料の20％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>

                  <tr>
                    <th rowSpan={2} className="u_tal">
                      開催日の
                      <br />
                      60日前から31日前まで
                    </th>
                    <td>取り消し</td>
                    <td>
                      ご予約会場の宴会時間・会議室料の40％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>
                  <tr>
                    <td>変更</td>
                    <td>
                      ご予約会場の宴会時間・会議室料の30％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>

                  <tr>
                    <th rowSpan={2} className="u_tal">
                      開催日の
                      <br />
                      30日前から16日前まで
                    </th>
                    <td>取り消し</td>
                    <td>
                      ご予約会場の宴会時間・会議室料の60％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>
                  <tr>
                    <td>変更</td>
                    <td>
                      ご予約会場の宴会時間・会議室料の50％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>

                  <tr>
                    <th rowSpan={2} className="u_tal">
                      開催日の
                      <br />
                      15日前から3日前正午まで
                    </th>
                    <td>取り消し</td>
                    <td>
                      ご予約会場の宴会時間・会議室料の80％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>
                  <tr>
                    <td>変更</td>
                    <td>
                      ご予約会場の宴会時間・会議室料の70％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>

                  <tr>
                    <th rowSpan={2} className="u_tal">
                      開催日の
                      <br />
                      3日前正午より当日
                    </th>
                    <td>取り消し</td>
                    <td>
                      ご予約会場の宴会時間・会議室料の100％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>
                  <tr>
                    <td>変更</td>
                    <td>
                      ご予約会場の宴会時間・会議室料の100％
                      <br />
                      および実費諸費用の請求
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          <p className="u_mb25">
            （上記の宴会場室料については、「宴会場利用料金表」を基に算出させていただきます。）
            <br />
            ※上記取り消し料・期日変更料には、サービス料は加算されません。
            <br />
            ※取り消し・変更いずれの場合も、手配が完了している（司会・コンパニオン・装花・看板等）実費諸費用に関しては、100%料金と税金を頂戴させていただく場合がございます。
          </p>
          <h3 className="c_headingLv5">8. 駐車場のご利用</h3>
          <p className="u_mb25">
            ご宴会等にご出席のお客様が駐車場（ランドマークタワー内駐車場）を利用される場合には、一律一台３時間迄のサービスとなります。駐車券をご持参の上、宴会場クロークでお申し付けください。また、３時間以上の駐車料金は自己負担とさせていただきます。
            <br />
            展示会・会議でのご利用のお客様が駐車場を利用される場合の駐車料金は、すべて自己負担とさせていただきます。
            <br />
            尚、当ホテルでは、「飲酒運転根絶運動」を推進しており、酒気を帯びて運転されるお客様へは駐車場サービス（３時間）をお断りいたします。別途、代行運転サービスのご紹介や１日駐車場利用券（有料）の販売も承っております。
          </p>
          <h3 className="c_headingLv5">9. 装飾・余興などの手配</h3>
          <p className="u_mb25">
            ご宴会等に係わる装飾・音響・照明・余興・バンケットコンパニオン等につきましては、ホテルより契約業者に手配させていただきます。お客様がホテルの契約業者以外に直接依頼される場合には、ご宴会等を円滑に運営する為、事前にホテルの担当係員へご連絡いただき、了承させていただいた後にご手配ください。（ホテルに事前の同意を得ないで直接業者にご依頼なさることはご遠慮ください。）
          </p>
          <h3 className="c_headingLv5">10. 直接ご依頼の業者に対するお願い</h3>
          <p className="u_mb25">
            ホテルの了承のもとにお客様が直接ご依頼された業者が行う、ご宴会等に関連する装飾・余興等の機器および機材の搬入・搬出、または看板等のサイズ、取り付け方法、設置場所の設定につきましては、ホテルの美観、導線等を考慮し、ホテルの指示にしたがい行っていただくよう業者の方々にご案内させていただきます。お客様側よりも、その旨ご連絡ください。
            <br />
            尚、ホテル係員・ホテル協力会社係員の立会いが必要な場合には、立会い人件費を頂戴させていただきます。
          </p>
          <h3 className="c_headingLv5">11. 損害補償</h3>
          <p className="u_mb25">
            お客様（主催者およびご宴会等にご出席の総てのお客様）あるいはお客様が直接手配された業者の方々は、ホテルの施設・什器備品等を破損・損傷させることのないよう充分ご注意ください。万一、破損・損傷が発生した場合には、その修復に関してホテルよりご指示申し上げますので、それに合わせて速やかに修理していただくか、損害補償金（休業補償金を含む）をご負担いただきます。
          </p>
          <h3 className="c_headingLv5">12. 禁止事項</h3>
          <p className="u_mb25">
            次に掲げる各項目につきましては、ご遠慮くださいますようお願い申し上げます。
            <br />
            A. 法令または公序良俗に反する行為
            <br />
            B.
            盲導犬・介助犬・聴導犬以外の犬・猫・小鳥その他の愛玩動物・家畜類の持ち込み
            <br />
            C. 発火または引火性のある物品や危険物の持ち込み
            <br />
            D. 悪臭を発する物の持ち込み
            <br />
            E. 賭博等風紀を乱す行為および他のお客様のご迷惑になる言動・行動
            <br />
            F. ご予約時の使用目的以外のご利用
            <br />
            G. 備品の移動
            <br />
            H. その他、法令で禁じられいる行為
          </p>
          <h3 className="c_headingLv5">13. 利用禁止事項</h3>
          <p className="u_mb25">
            ご宴会等にご出席のお客様の中に次の該当者がいる場合、ご利用は禁止させていただきます。
            <br />
            A. 暴力団員、または暴力団等の暴力関係団体その他反社会的勢力の関係者
            <br />
            B. 暴力団または暴力団員が事業活動を支配する法人その他の団体の関係者
            <br />
            C. 病毒伝播のおそれのある伝染病等の疾病に罹った方
          </p>
          <h3 className="c_headingLv5">14. 解約</h3>
          <p className="u_mb25">
            お客様およびご宴会等にご出席のお客様が上記１．～１３．の規約に違反する場合、もしくは違反する恐れがある場合、お申し込みの際に申込金をお支払いいただけない場合、お申し込みもしくはお打合せ等の際に、虚偽の事実を申告し、もしくは重要な事実を申告しなかった場合、その他、宴会場のご利用が不適当であるとホテル側が判断した場合には、ご宴会等のお申し込みをお断りするか、既にご予約いただいたご宴会等でも、解約の上、取り消し料をご請求させていただくことがございますので、予めご了承ください。
            <br />
            特に３．前払い金をご請求させていただいた後、指定の日までにご入金がなされない場合には、前項の恐れのある場合と判断して、ご宴会等のご予約を解約の上、お取り消し料をご請求させていただくことがございますので、予めご了承ください。
          </p>
        </LWrap>
      </section>
      {/* <ConceptMedia /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
